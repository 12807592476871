$(document).ready(function () {

    $('#newsletter-form').on('submit', function (evt) {
        evt.preventDefault();
        console.log($('#newsletter-form').serialize());

        $.ajax({
            url: '/newsletter-subscribe',
            method: 'POST',
            data: $('#newsletter-form').serialize(),
            success: function (data) {
                console.log(data);
                $('#newsletter-help').text(data.message).slideDown(2500);
            },
            error: function (data) {
                console.log(data.responseJSON.error);
                $('#newsletter-help').text(data.responseJSON.error).slideDown(2500);
            }
        });
    });

});
