var TypeaheadKDB = {
    init: function () {
        $('form#main-search-form').submit(function (event) {
            event.preventDefault();
            $('.typeahead-kdb').typeahead('val', '');
        });
        this.typeItem();
    },
    tokenize: function (str) {
        str = "" + str + "";
        str = str.replace('\(', '').replace('\)', '');
        return str ? str.split(/\s+/) : [];
    },
    suggestItem: function (items) {
        var typeaheadKDB = this;
        var names = new Bloodhound({
            datumTokenizer: function (items) {
                // console.log(typeaheadKDB.tokenize(items.name));
                return typeaheadKDB.tokenize(items.name);
            },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: items
        });

        names.initialize();

        $('.typeahead-kdb').typeahead({
            highlight: true
        }, {
            name: 'names',
            displayKey: 'name',
            limit: 50,
            source: names.ttAdapter(),
            templates: {
                empty: '<div class="empty-message">No matches.</div>',
                suggestion: Handlebars.compile('<div>{{name}} – <span style="font-size: 12px; color: #ccc">{{item_type}}</span></div>')
            }
        }).on('typeahead:selected', function (event, item) {
            $('.typeahead-kdb').typeahead('val', '');
            location.href = item.url;
        });

    },
    typeItem: function () {
        var typeaheadKDB = this;
        $.ajax({
            url: '/kdb/all'
        }).done(function (items) {
            // console.log(items);
            typeaheadKDB.suggestItem(items);
        }).fail(function () {
            console.log('failed to load data from kdb.');
        });
    }
};
