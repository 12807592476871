jQuery(document).ready(function($){

    "use strict";

    var winHeight = $(window).height();
    var winWidth = $(window).width();

    $(window).resize(function() {
        winHeight = $(window).height();
        winWidth = $(window).width();

        $('.site-header .bg-img').height(winHeight);
    });


    $('.site-header .bg-img').height(winHeight);

    // Swiper Plugin
    var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        effect: 'coverflow',
        autoplay: 3000,
        roundLengths: true,
        spaceBetween: -300,
        grabCursor: true,
        slidesPerView: 2,
        loop: true,
        centeredSlides: true,
        paginationClickable: true,
        coverflow: {
            rotate: 0,
            stretch: 0,
            depth: 800,
            modifier: 1,
            slideShadows : false
        }
    });

    $.fn.owlCarousel && $(".owl-carousel").owlCarousel({
        items : 1, //10 items above 1000px browser width
        itemsDesktop : [1920,1], //5 items between 1000px and 901px
        itemsDesktopSmall : [1280,1], // betweem 900px and 601px
        itemsTablet: [768,1], //2 items between 600 and 0
        itemsMobile : [480,1], // itemsMobile disabled - inherit from itemsTablet option
        autoPlay : true,
        paginationSpeed : 300,
        slideSpeed : 300
    });

    $.fn.owlCarousel && $(".treatment-nav").owlCarousel({
        items : 3, //10 items above 1000px browser width
        itemsDesktop : [1920,3], //5 items between 1000px and 901px
        itemsDesktopSmall : [1280,3], // betweem 900px and 601px
        itemsTablet: [768,3], //2 items between 600 and 0
        itemsMobile : [480,1], // itemsMobile disabled - inherit from itemsTablet option
        autoPlay : false,
        mouseDrag: false,
        touchDrag: false,
        paginationSpeed : 300,
        slideSpeed : 300
    });

    $.fn.owlCarousel && $(".owl-service").owlCarousel({
        items : 4, //10 items above 1000px browser width
        navigation : true,
        navigationText: ['<img class="img-responsive svg-arrow" src="../img/home/svg/left-angle.svg">', '<img class="img-responsive svg-arrow" src="../img/home/svg/right-angle.svg">'],
        itemsDesktop : [1920,4], //5 items between 1000px and 901px
        itemsDesktopSmall : [1280,4], // betweem 900px and 601px
        itemsTablet: [768,2], //2 items between 600 and 0
        itemsMobile : [480,1], // itemsMobile disabled - inherit from itemsTablet option
        autoPlay : true,
        paginationSpeed : 300,
        slideSpeed : 300,
    });

    $.fn.owlCarousel && $(".owl-testimonial").owlCarousel({
        items : 1, //10 items above 1000px browser width
        navigation : true,
        navigationText: ['<img class="img-responsive svg-arrow" src="../img/home/svg/left-arrow.svg">', '<img class="img-responsive svg-arrow" src="../img/home/svg/right-arrow.svg">'],
        pagination: false,
        itemsDesktop : [1920,1], //5 items between 1000px and 901px
        itemsDesktopSmall : [1280,1], // betweem 900px and 601px
        itemsTablet: [768,1], //2 items between 600 and 0
        itemsMobile : [480,1], // itemsMobile disabled - inherit from itemsTablet option
        autoPlay : true,
        paginationSpeed : 300,
        slideSpeed : 300,
    });

    // Flexslider
    $.fn.flexslider && $('.notice-slider-nav').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        asNavFor: '.notice-slider'
    });

    $.fn.flexslider && $('.notice-slider').flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        sync: ".notice-slider-nav"
    });

    $.fn.fitVids && $(".video, .ytube-video").fitVids();

    $('a.scroll[href^="#"]').on('click',function (e) {
        e.preventDefault();
        $('html, body').stop().animate({
            'scrollTop': $($(this).attr('href')).offset().top
        }, 1500, 'easeInOutCirc', function () {});
    });

    if($('.checkbox-list').length > 0) {
        $(".checkbox input").on('change', function(){
            var checkbox = $(this),
            listItem = checkbox.parents('li');
            // console.log(listItem);

            checkbox.is(':checked') ?
            listItem.addClass('bg-checked') :
            listItem.removeClass('bg-checked');

        }).change();
    }

    $.fn.slider && $( "#time-range" ).slider({
        range: true,
        min: 0,
        max: 24,
        values: [ 3, 10 ],
        slide: function( event, ui ) {
            $( "#time-val" ).val(  ui.values[ 0 ] + " - " + ui.values[ 1 ] );
        }
    });

    $( "#time-val" ).val( $( "#time-range" ).slider( "values", 0 ) +
    " - " + $( "#time-range" ).slider( "values", 1 ) );

    $.fn.slider && $( "#price-range" ).slider({
        range: true,
        min: 0,
        max: 5000,
        values: [ 100, 1500 ],
        slide: function( event, ui ) {
            $( "#price-amount" ).val( ui.values[ 0 ] + "-" + ui.values[ 1 ] );
        }
    });

    $( "#price-amount" ).val( $( "#price-range" ).slider( "values", 0 ) +
    " - " + $( "#price-range" ).slider( "values", 1 ) );


    $('.menutoggle').on('click', function(e) {
        e.preventDefault();

        if( winWidth > 767) {
            $('body').toggleClass('toggled');
        }

        if( winWidth < 768) {
            $('.sidebar-wrapper').slideToggle("slow");
        }
    });

    var circleLoader = $('.author-img-circle');

    $.fn.circleLoader && circleLoader.circleProgress({
        startAngle: -Math.PI / 4 * 2,
        thickness: 5,
        lineCap: 'round',
        fill: { color: '#00baff' }
    });

    // Homepage Fixed nav

    var homePage = $('.home-content').length;
    var homeOffset = $('.symptom-checker').offset();
    var mediaOffset = ($('.health').length > 0) ? $('.health').offset() : "";
    // console.log(($('.health').length > 0))

    if( winWidth > 767){
        if( homePage ) {
            $(window).on('scroll', function () {
                if ($(this).scrollTop() > homeOffset['top']) {
                    $('.home-fixed-nav-wrap').show();
                } else {
                    $('.home-fixed-nav-wrap').hide();
                }

                if ($(this).scrollTop() > mediaOffset['top'] - 500) {
                    $('.home-fixed-nav-wrap').hide();
                }

            });
        }
    }

    var homeScrollOffset = 58;

    function homeMenuScroll() {
        $('.home-fixed-nav a[href^="#"]').on('click', function (e) {
            e.preventDefault();
            $('html, body').stop().animate({
                'scrollTop': $($(this).attr('href')).offset().top - homeScrollOffset
            }, 1500, 'easeInOutCirc', function () {
            });
        });
    }

    homeMenuScroll();


    // End Homepage Fixed nav


    var winHeight = $(window).height();
    // var offsetSecondary = $('.header-secondary-nav').offset()['top'];
    if ($('.header-secondary-nav').length > 0) {
        var offsetSecondary = ($('.knowledge-base-content').length > 0) ? $('.knowledge-base-content .header-secondary-nav').offset().top : '';
    }

    var duration = 500;
    var footerOffset =( $('.site-footer').length > 0 ) ? $('.site-footer').offset().top : '';

    // console.log(footerOffset);

    if(winWidth > 767 ) {
        var secondMenuWidth = $('.header-secondary-nav').width();

        $(window).on('scroll', function () {
            if ($(this).scrollTop() > offsetSecondary + winHeight/2) {
                $('.header-secondary-nav').addClass('fixed animated fadeInDown');
                $('.header-secondary-nav.fixed').css('width', secondMenuWidth);
            } else{
                $('.header-secondary-nav').removeClass('fixed animated fadeInDown');
            }

            if ( $(this).scrollTop() > footerOffset-winHeight ){
                $('.header-secondary-nav').removeClass('fixed animated fadeInDown');
            }
        });
    }

    var scrollOffset = 25;


    function menuScroll() {
        $('.secondary-nav-list a[href^="#"]').on('click', function (e) {
            e.preventDefault();
            $('html, body').stop().animate({
                'scrollTop': $($(this).attr('href')).offset().top - scrollOffset
            }, 1500, 'easeInOutCirc', function () {
            });
        });
    }

    menuScroll();


    function centerModal() {
        $(this).css('display', 'block');
        var $dialog  = $(this).find(".modal-dialog"),
        offset       = ($(window).height() - $dialog.height()) / 2,
        bottomMargin = parseInt($dialog.css('marginBottom'), 10);

        // Make sure you don't hide the top part of the modal w/ a negative margin if it's longer than the screen height, and keep the margin equal to the bottom margin of the modal
        if(offset < bottomMargin) offset = bottomMargin;
        $dialog.css("margin-top", offset);
    }

    if( !$('.modal').hasClass('modal-top')){
        $(document).on('show.bs.modal', '.modal', centerModal);
        $(window).on("resize", function () {
            $('.modal:visible').each(centerModal);
        });

        centerModal();
    }



    $.fn.select2 && $(".custom-select" ).each(function(i, v) {
        // console.log($(this));
        $(this).select2({

            placeholder: $(v).data('placeholder'),
            width: "100%"
            // allowClear: false
        });
    });

    // $(".custom-select").select2({
    // 	// placeholder: 'select'
    // })

    $.fn.select2 && $(".multiple-selector" ).each(function(i, v) {
        $(this).select2({
            placeholder: $(v).data('placeholder'),
            width: "100%"
        });
    });



    // Javascript Specific Code
    if(document.getElementById("diet-chart")) {
        var ctx = document.getElementById("diet-chart").getContext("2d");

        var data = [

            {
                value: 2100,
                color:"#00b0d9",
                highlight: "#00b0d9",
                label: "শর্করা"
            },

            {
                value: 2000,
                color: "#83e3ff",
                highlight: "#83e3ff",
                label: "চর্বি"
            }

        ]



        var options = {
            customData: data,
            segmentShowStroke : true,
            percentageInnerCutout : 50,
            segmentStrokeWidth : 5,
            showTooltips: false,
            responsive: true,
            onAnimationComplete: function(){

                dietChart.segments.forEach(function(data){

                    var centreAngle = data.startAngle + ((data.endAngle - data.startAngle) / 2),
                    rangeFromCentre = data.outerRadius - 10,
                    centerX = Math.round(data.x + (Math.cos(centreAngle) * rangeFromCentre)),
                    centerY = Math.round(data.y + (Math.sin(centreAngle) * rangeFromCentre));

                    ctx.beginPath();
                    ctx.arc(centerX, centerY, 30, 0, 2 * Math.PI, false);
                    ctx.fillStyle = data.fillColor;
                    ctx.fill();
                    ctx.lineWidth = 5;
                    ctx.strokeStyle = '#fff';
                    ctx.textAlign = "center";
                    ctx.textBaseline = 'middle';
                    ctx.stroke();

                    ctx.font="13px Open Sans";
                    ctx.fillStyle = "white";
                    ctx.fillText( Math.round((100/dietChart.total)*data.value) + '%' , centerX, centerY);

                });


            },

            legendTemplate : "<ul class=\"diet-legend\"><% for (var i=0; i<segments.length; i++){%><li><div class=\"legend-block-outer\"><div class=\"left-legend-block\"><p class=\"legend-color-block\" style=\"background-color:<%= segments[i].fillColor%>\"></p><p><%if(segments[i]){%><%=segments[i].label %><%}%></p></div><div class=\"right-legend-block\"><%if(segments[i].value){%><%=segments[i].value%><%}%></div></div></li><%}%></ul>"
        }

        var dietChart = new Chart(ctx).Doughnut(data, options);
        // console.log(dietChart);
        var legend = dietChart.generateLegend();

        $('#diet-legend-wrap').append(legend);

    }

    function adjustThumbnailHeight() {
        var heights = [ ];
        $(".food-thumbnail .caption, .video-caption").each(function() {
            heights.push( $(this).height() );
        });
        var max = Math.max.apply(null, heights);
        $(".food-thumbnail .caption, .video-caption").each(function() {
            $(this).height(max);
        });

    }

    function inheritThumbailHeight() {
        $(".food-thumbnail .caption, .video-caption").each(function() {
            $(this).css("height", "inherit");
        });
    }

    if( winWidth > 767 ) {

        inheritThumbailHeight();
        adjustThumbnailHeight();
    }

    // Bootstrap Tooltip Enabling
    $('[data-toggle="tooltip"]').tooltip();

    // Confirmation Message Before submitting
    $("#submitBtn").click(function(){
        if (confirm("Click OK to continue?")){
            console.log('You confirmed to submit');
        }
    });

    // Easyautocomplete Search Suggest Rules
    var states = {
        data: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
        'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
        'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
        'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
        'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
        'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
        'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
        'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ]
};


// Typehead Search Suggest Rules

var states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'Arkansasa', 'Alass', 'Arakan', 'California',
'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

var states = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    // `states` is an array of state names defined in "The Basics"
    local: states
});


$.fn.typeahead && $('.typehead-search-suggest').typeahead({
    hint: true,
    highlight: true,
    minLength: 1
},
{
    name: 'states',
    source: states
});


// Skillbar Loading Animation Processing
$('.calorie').each(function(){
    $(this).find('.calorie-bar').animate({
        width:$(this).attr('data-percent')
    },1000);
});

//header search-trigger
$('.search-trigger a').on('click', function(e){
    e.preventDefault();
    ( $('.search-slide-in').hasClass('search-toggled') )
    ? $('.search-slide-in').removeClass('search-toggled')
    : $('.search-slide-in').addClass('search-toggled');
});

$('button.btn-dismiss').on('click', function(e) {
    e.preventDefault();
    $('.search-slide-in').removeClass('search-toggled');
});

$('.more-trigger').on('mouseenter', function(e) {
    e.preventDefault();
    $('.blocked-nav').addClass('more-toggled fadeInDown');
    $('.blocked-nav').removeClass('fadeOutDown');
});


$('.more-close').on('click', function(e) {
    e.preventDefault();
    $('.blocked-nav').removeClass('fadeInDown');
    $('.blocked-nav').addClass('fadeOutDown');
});

// Same Height Issue fixing

var dirSingleWrapHeight = $('.dir-single-wrap-inner').height();

if( winWidth > 991) {
    // console.log('ys');
    $('.dir-single-wrap-inner-right').css('height', dirSingleWrapHeight + "px");
    $('.dir-contact-info').each(function() {
        var self 					= $(this),
        dirContactHeight 		= self.height(),
        dirContactHeightHalf 	= dirContactHeight/2;

        self.css({
            'height'		: dirContactHeight + "px",
            'margin-top' 	: "-" + dirContactHeightHalf + "px"
        });
    });


}

// Vertical Caption Aligining

$('.caption-vertical').each(function(){
    var $this = $(this);

    var captionHeight = $this.height();
    var captionHeightHalf = captionHeight/2;

    $this.css({
        'height' 		: captionHeight + "px",
        'margin-top'	: "-" + captionHeightHalf + "px"
    });

});


$( ".details-inner-content input" ).click(function() {
    var $input = $( this );
    // console.log($input);
    // console.log($input.closest('.details-inner').siblings() );

    if( $input[0].id === 'toggle-male') {
        $input.closest('.details-inner').addClass('human-checked');
        $input.closest('.details-inner').siblings().removeClass('human-checked');
        // console.log('it is male');
    } else if ( $input[0].id === 'toggle-female' ) {
        $input.closest('.details-inner').addClass('human-checked');
        $input.closest('.details-inner').siblings().removeClass('human-checked');
    } else if ( $input[0].id === 'toggle-kid' ) {
        $input.closest('.details-inner').addClass('human-checked');
        $input.closest('.details-inner').siblings().removeClass('human-checked');
    } else {
        $input.closest('.details-inner').removeClass('human-checked');
    }

    // console.log($input + $input.prop( "checked" ));
});

// console.log($('#humanChecked:checked').val());



var foodCurrOffset = ($('.diet-plan-table').length > 0) ? $('.diet-plan-table').offset().top - winHeight : '';
var foodFootOffset = ( $('.site-footer').length > 0 ) ? $('.site-footer').offset().top - winHeight : '';


// Health Vault Page Javascript
$('#healthVault').DataTable();

$('.input-daterange').datepicker();

$('a.vault-dropdown-toggle').on('click', function(e) {
    e.preventDefault();

    ( $(this).closest('.vault-dropdown').hasClass('active') )
    ? $(this).closest('.vault-dropdown').removeClass('active')
    : $(this).closest('.vault-dropdown').addClass('active').siblings().removeClass('active');

    // console.log($(this).closest('.vault-dropdown').siblings().removeClass('active'));
});


// kickassTextSlider Initialization
$.fn.kickassTextSlider && $('.kickassTextSlider').kickassTextSlider();

// Profile Page rules
$('.date-of-birth').datepicker().on('changeDate', function(e) {
    $('.date-of-birth').datepicker('hide');
});

$.fn.magnificPopup && $('.image-popup').magnificPopup({
    delegate: 'a.gallery-item',
    removalDelay:500,
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    image: {
        verticalFit: true,
        titleSrc: function(item) {
            return item.el.attr('data-title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
        }
    },
    gallery: {
        enabled: true
    },
    zoom: {
        enabled: true,
        duration: 300, // don't foget to change the duration also in CSS
        opener: function(element) {
            return element.find('img');
        }
    }
});


var HazaModal = {
    init: function() {
        var self        = this;
        self.elems      = $('.procedure-list');
        self.closeBtn   = $('.haza-modal-container .close-btn');
        self.overlay	= $('.diagnosis-overlay');
        self.handleProcess();
    },

    handleProcess: function() {
        var self = this;
        self.elemsClickHandle();
    },

    elemsClickHandle: function() {
        var self  = this;

        self.elems.on('click', 'li > a', function(e) {
            e.preventDefault();
            var $this = $(this);

            var thisAttr        = $this.attr('href'),
            thisOffset          = $this.offset(),
            thisWidth           = $this.parent().width(),
            thisMainContainer   = $this.closest('.possible-cure'),
            modalContainer      = $('body').find(thisAttr);

            $('.left-arrow').css('top', (thisOffset.top / 2) + 2.5 );
            // $.scrollTo($(this), 500);
            thisMainContainer.addClass('no-scroll');

            modalContainer.removeClass('hidden').css({
                top: thisOffset.top / 2,
                left: thisOffset.left + thisWidth + 5
            }).animate({opacity: 1}, 300);

            self.overlay.show();
            self.closeModal();
        });

    },

    closeModal: function () {
        var self = this;

        self.closeBtn.on('click', function() {
            var $this = $(this);
            $this.closest('.haza-modal-container').addClass('hidden');
            $('.possible-cure').removeClass('no-scroll');
            self.overlay.hide();
        });
    }
};


$.fn.hazaModal = function() {
    var hazaModal = Object.create(HazaModal);
    return hazaModal.init();
}

$.fn.mHazaModal = function() {
    var mHazaModal = {};
    mHazaModal.prototype = Object.create(HazaModal);

    mHazaModal.prototype.elemsClickHandle = function () {
        var self        = this;
        self.elems      = $('.procedure-list');

        self.elems.on('click', 'li > a', function(e) {
            e.preventDefault();
            var $this = $(this);

            var thisAttr        = $this.attr('href'),
            thisOffset          = $this.offset(),
            thisWidth           = $this.parent().width(),
            thisHeight          = $this.parent().height(),
            thisMainContainer   = $this.closest('.possible-cure'),
            modalContainer      = $('body').find(thisAttr);


            $('.left-arrow').css('top', 0 );
            // $.scrollTo($(this), 500);
            thisMainContainer.addClass('no-scroll');

            modalContainer.removeClass('hidden').css({
                top: thisOffset.top + thisHeight,
                left: thisOffset.left
            }).animate({opacity: 1}, 300);

            self.closeModal();
        });
    }
    return mHazaModal.prototype.init();
}

$.fn.sHazaModal = function() {
    var sHazaModal = {};
    sHazaModal.prototype = Object.create(HazaModal);

    sHazaModal.prototype.elemsClickHandle = function () {
        var self        = this;
        self.elems      = $('.procedure-list');

        self.elems.on('click', 'li > a', function(e) {
            e.preventDefault();
            var $this = $(this);

            var thisAttr        = $this.attr('href'),
            thisOffset          = $this.offset(),
            thisWidth           = $this.parent().width(),
            thisHeight          = $this.parent().height(),
            thisMainContainer   = $this.closest('.possible-cure'),
            modalContainer      = $('body').find(thisAttr);


            $('.left-arrow').css('top', 0 );
            // $.scrollTo($(this), 500);
            thisMainContainer.addClass('no-scroll');

            modalContainer.removeClass('hidden').css({
                top: thisOffset.top + thisHeight + 5,
                left: 0
            }).animate({opacity: 1}, 300);

            self.closeModal();
        });
    }
    return sHazaModal.prototype.init();
}

if( winWidth > 991 ){
    $.fn.hazaModal();
} else if ( winWidth > 767 && winWidth < 992 ) {
    $.fn.mHazaModal();
} else if ( winWidth < 768 ) {
    $.fn.sHazaModal();
}


$('span.title-toggler').on('click', function() {
    var currElem = $(this).closest('li'),
    leftBlock;
    if ( currElem.hasClass('procedure-show')) {
        leftBlock = currElem.removeClass('procedure-show');
    } else {
        leftBlock = currElem.addClass('procedure-show').siblings().removeClass('procedure-show');
    }
    return leftBlock;
});

// Category Block Menu
$('.category-btn-trigger').on('click', function(e) {
    e.preventDefault();
    var categoryNav = $('.category-nav-container');

    if ( categoryNav.is(':hidden')) {
        $('.category-nav-container').show('slow');
    } else {
        $('.category-nav-container').hide('slow');
    }
});

$('.doc-profile-footer, .dir-profile-footer').on('click', 'div[class^=star-]', function(e) {
    var elm = $(this), cls = elm.attr('class').split(/\s+/).shift();
    $('a[href="#reviews"]').click();
    $('html, body').animate({
        scrollTop: $('textarea[name="comment"]').offset().top
    }, 500);
    $('textarea[name="comment"]').focus().select();
    $('#reviews .stars').find('.' + cls).click();
});

//Footer Up Arrow
$(window).on('scroll', function() {
    if( $(this).scrollTop() > winHeight ) {
        $('.up-arrow').css('opacity', 1).show();
    } else {
        $('.up-arrow').hide();
    }
});

// Latest Homepage Service Slider Code
$.fn.slick && $('.service-slider').slick({
    autoplay: true,
    arrows: false
});

$('.service-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){

    $('.service-nav-dots > li').map(function(data) {
        if (data === nextSlide) {
            $(this).addClass('active')
            .siblings()
            .removeClass('active');
        }
    });
});

$('.service-nav-dots')
.on('mouseenter', 'li', function() {
    $(this).addClass('active')
    .siblings()
    .removeClass('active');
    $('.service-slider').slick('slickGoTo', $(this).data('service'));
    $('.service-slider').slick('slickPause');
})
.on('mouseleave', 'li', function() {
    $('.service-slider').slick('slickPlay');
});

// $('.features > .container:after').css('left', $('.features > .container').offset().left));
// Feedback Slider
$.fn.slick && $('.feedback-slider').slick({
    autoplay: true,
    prevArrow: '<p class="feed-left-arrow"><i class="fa fa-angle-left"></p>',
    nextArrow: '<p class="feed-right-arrow"><i class="fa fa-angle-right"></p>'
});


// featured on media section
$.fn.slick && $('.newspapers').slick({
    centerMode: true,
    draggable: false,
    slidesToShow: 4,
    prevArrow: '<p class="news-left-arrow"><i class="glyphicon glyphicon-menu-left"></p>',
    nextArrow: '<p class="news-right-arrow"><i class="glyphicon glyphicon-menu-right"></p>'
});

$('.newspapers').on('beforeChange', function(event, slick, currentSlide, nextSlide){

    $('.newspaper-title > li').map(function(data) {
        if (data === nextSlide) {
            $(this).addClass('active')
            .siblings()
            .removeClass('active');
        }
    });
});

var basicQuestion = $('.basic-question').slick({
    prevArrow: '<p><i class="fa fa-angle-left"></i></p>',
    nextArrow: '<p><i class="fa fa-angle-right"></i></p>',
    appendArrows: '.prev-next',
    infinite: false,
    adaptiveHeight: true
});

var updateSlickUpdateHeight = function() {
    var updatedHeight = $('.slick-active').height();
    $('.slick-list').animate({
        height: updatedHeight
    },500);
}

$('input[type="checkbox"], input[type="radio"]').on('click', function() {
    var $this = $(this);
    if($this.context.value === 'others' && $this.is(':checked')) {
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="smoke"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'smoker') {
        $('.full-block.if-smoker').show();
        if($('.full-block.if-retired-smoker').is(':visible')) {
            $('.full-block.if-retired-smoker').hide();
        }
        setTimeout(updateSlickUpdateHeight, 300);
    } else if ( $this.context.value === 'retired-smoker' ) {
        $('.full-block.if-retired-smoker').show();
        if($('.full-block.if-smoker').is(':visible')) {
            $('.full-block.if-smoker').hide();
        }
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-smoker').hide();
        $('.full-block.if-retired-smoker').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="alcoholic"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.full-block.if-alcoholic').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-alcoholic').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="drugs"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.full-block.if-drug-addict').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-drug-addict').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="disability"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.full-block.if-disabled').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-disabled').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="allergy"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'food_allergy' && $this.is(':checked')) {
        $('.full-block.if-food-allergy').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else if ( $this.context.value === 'drugs' && $this.is(':checked')) {
        $('.full-block.if-drugs-allergy').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else if ( $this.context.value === 'animals' && $this.is(':checked')) {
        $('.full-block.if-animals-allergy').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else if( $this.context.value === 'food_allergy' && !($this.is(':checked')) ) {
        $('.full-block.if-food-allergy').hide();
    } else if( $this.context.value === 'drugs' && !($this.is(':checked')) ) {
        $('.full-block.if-drugs-allergy').hide();
    } else if ($this.context.value === 'animals' && !($this.is(':checked'))) {
        $('.full-block.if-animals-allergy').hide();
    }
});

$('input[name="operation"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.full-block.if-operation-done').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-operation-done').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="artificial"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.full-block.if-artificial-body-part').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-artificial-body-part').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="present_medicine"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.full-block.if-medicine-taken').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-medicine-taken').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="menopog"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'no') {
        $('.full-block.if-no-menopog').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-no-menopog').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="pregnancy"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'no') {
        $('.full-block.if-not-pregnant').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-not-pregnant').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="pregnancy"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'no') {
        $('.full-block.if-not-pregnant').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.full-block.if-not-pregnant').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('input[name="pain_after_period"]').on('change', function() {
    var $this = $(this);
    if($this.context.value === 'yes') {
        $('.if-pain-after-period').show();
        setTimeout(updateSlickUpdateHeight, 300);
    } else {
        $('.if-pain-after-period').hide();
        setTimeout(updateSlickUpdateHeight, 300);
    }
});

$('.hidden-input').hide();

$('.edit-profile').on('click', function() {

    if( $('.toggle-input').is(':visible') ) {
        $('.toggle-input').hide();
        $('.hidden-input').show();
    } else {
        $('.toggle-input').show();
        $('.hidden-input').hide();
    }

});


}); // DOCUMENT READY END
